@import "../../sass/variables.scss";

.about {
    background: $purple;
    color: white;
    &__img {
        transform: scaleX(-1);
    }

    .emph {
        color: $orange;
        font-weight: 700;
    }
}
