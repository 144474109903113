@import "../../sass/variables.scss";
.portfolio {
    background: white;
    &__container {
        max-width: 1024px;
        margin-left: auto;
        margin-right: auto;
    }
    &__square {
        height: 300px;
        width: 300px;
        margin: 20px;
        background: #ddd;
        cursor: pointer;
    }
}
$height: 640px;
$mobile-height-mult: 0.5578;
$mobile-height: $height * $mobile-height-mult;
.modal-body {
    padding: 0 !important;
    height: $height;
    @media (max-width: $mobile) {
        height: $mobile-height;
    }
    iframe {
        height: $height;
        @media (max-width: $mobile) {
            height: $mobile-height;
        }
    }
}
