@import "../../sass/variables.scss";
@import "~bootstrap/scss/bootstrap";
.sting-video {
    &__row > .col {
        height: 5rem;
        @include media-breakpoint-up(sm) {
            height: 9rem;
        }
        @include media-breakpoint-up(md) {
            height: 13rem;
        }
    }

    &__top-row {
        background: $teal;
    }

    & &__video {
        top: 0rem;
        height: 10rem;
        width: 20rem;
        @include media-breakpoint-up(sm) {
            height: 19rem;
            width: 37rem;
        }
        @include media-breakpoint-up(md) {
            height: 25rem;
            width: 47rem;
        }
    }
}
