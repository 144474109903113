@import "../../sass/variables.scss";
.service-menu {
    background: white;
    display: flex;
    min-height: 100%;
    justify-content: center;
    align-items: center;

    .h1 {
        margin-bottom: 4.5rem;
    }

    &__container {
        width: 100%;
    }

    .service-menu-box {
        &:hover {
            .service-menu-box {
                &__overlay {
                    opacity: 0.9;
                }

                &__overlay-text {
                    opacity: 1;
                }

                &__box {
                    transform: scale(1.02);
                }
                &__text {
                    color: $orange;
                }
            }
        }

        &__box {
            width: 300px;
            height: 300px;
            @media (max-width: 1500px) {
                width: 250px;
                height: 250px;
            }
            @media (max-width: 1220px) {
                width: 220px;
                height: 220px;
            }
            @media (max-width: 990px) {
                width: 300px;
                height: 300px;
            }
            border-radius: 14px;
            position: relative;
            transition: transform 0.4s;
            img {
                height: 100%;
                width: 100%;
                padding: 2rem 0rem;
            }
        }

        &__text {
            display: inline-block;
            color: black;
            transition: color 0.3s;
            font-weight: 700;
            height: 100px;
            font-size: 1.6rem;
        }

        &__overlay {
            width: 100%;
            height: 100%;
            background: $teal;
            position: absolute;
            top: 0;
            border-radius: 14px;
            opacity: 0;
            transition: opacity 0.1s;
            color: white;
        }

        &__overlay-text {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: opacity 0.1s;
            font-size: 2.7rem;
            font-weight: bold;
            padding: 10px;
            @media (max-width: $mobile) {
                font-size: 2.6rem;
                line-height: 40px;
            }
        }
    }
}

.services {
    .h2 {
        color: $orange;
    }
    .svg {
        width: 95%;
    }
    b {
        color: $orange;
    }

    @keyframes speech-bubble {
        0% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(1.15, 1.15);
        }
        100% {
            transform: scale(1, 1);
        }
    }

    #Speech_bubble_1_ {
        animation-name: speech-bubble;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        transform-origin: 61% 64%;
    }

    background: white;

    @keyframes swing {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(-90deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }

    #Swing_1_ {
        animation: swing 3s infinite ease-in-out;
        transform-origin: 76.7% 35.1%;
    }

    @keyframes mouse {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(-60px);
        }
        100% {
            transform: translateX(0);
        }
    }

    #mouse {
        animation: mouse 5s infinite ease-in-out;
    }

    @keyframes flying-paper {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-25px);
        }
        100% {
            transform: translateY(0);
        }
    }

    #flying_paper {
        animation: flying-paper 3.5s infinite ease-in-out;
    }

    @keyframes red-bar {
        0% {
            transform: scaleY(1);
        }
        50% {
            transform: scaleY(0.9);
        }
        100% {
            transform: scaleY(1);
        }
    }

    #Red_bar {
        animation: red-bar 4.5s infinite ease-in-out;
        transform-origin: 0 79%;
    }

    @keyframes pink-bar {
        0% {
            transform: scaleY(1);
        }
        50% {
            transform: scaleY(1.1);
        }
        100% {
            transform: scaleY(1);
        }
    }

    #Pink_bar {
        animation: pink-bar 4s infinite ease-in-out;
        transform-origin: 0 79%;
    }

    @keyframes green-bar {
        0% {
            transform: scaleY(1);
        }
        50% {
            transform: scaleY(0.85);
        }
        100% {
            transform: scaleY(1);
        }
    }

    #Green_bar {
        animation: green-bar 4.2s infinite ease-in-out;
        transform-origin: 0 79%;
    }

    @keyframes orange-bar {
        0% {
            transform: scaleY(1);
        }
        50% {
            transform: scaleY(1.1);
        }
        100% {
            transform: scaleY(1);
        }
    }

    #Orange_bar {
        animation: orange-bar 4.2s infinite ease-in-out;
        transform-origin: 0 79%;
    }

    #shadow {
        mix-blend-mode: multiply;
    }
}
