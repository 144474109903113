@import "../../sass/variables.scss";
@import "~bootstrap/scss/bootstrap";

.header {
    transition: all 0.3s;
    position: absolute;
    width: 100%;

    &.navbar {
        position: absolute;
    }

    &--hidden {
        top: -110px !important;
    }
    .navbar-nav {
        margin-top: 5px;
        margin-bottom: 5px;
        transition: all 0.3s;
    }

    &__logo {
        position: absolute;
        top: 0;
        background-image: url("../../assets/images/Presmo logo_white with text.svg");
        height: 80px;
        margin: 0;
        width: 85px;
        transition: all 0.3s;
        background-position: left;
        background-repeat: no-repeat;
        @include media-breakpoint-up(sm) {
            width: 130px;
            margin: 26px 0 0 15px;
        }
    }

    &__link {
        font-size: 1.5rem;
        color: white;
        text-decoration: none;
        font-weight: 300;
        transition: all 0.15s;

        width: 115px;
        text-align: right;
        height: 50px;
        background-color: rgba(
            red($deep-purple2),
            green($deep-purple2),
            blue($deep-purple2),
            0.8
        );
        border-top: 0.5px solid rgba(255, 255, 255, 0.2);
        border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);

        @include media-breakpoint-up(md) {
            width: 110px;
            text-align: center;
            height: auto;
            background-color: transparent;
            border: none;
        }

        &:hover {
            color: white;
            font-weight: 700;
            text-decoration: none;
        }
    }
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255,  255, 255,  1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='1' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
    }
    .navbar-toggler {
        margin-top: 12px;
        color: white !important;
        border: none !important;
    }
}
