$enable-shadows: true;
$enable-negative-margins: true;
@import "~bootstrap/scss/bootstrap";
@import "./variables.scss";

@font-face {
    font-family: Akrobat;
    src: url(../assets/fonts/Akrobat-Thin.otf);
    font-weight: 100;
}
@font-face {
    font-family: Akrobat;
    src: url(../assets/fonts/Akrobat-ExtraLight.otf);
    font-weight: 200;
}
@font-face {
    font-family: Akrobat;
    src: url(../assets/fonts/Akrobat-Light.otf);
    font-weight: 300;
}
@font-face {
    font-family: Akrobat;
    src: url(../assets/fonts/Akrobat-Regular.otf);
    font-weight: 400;
}
@font-face {
    font-family: Akrobat;
    src: url(../assets/fonts/Akrobat-SemiBold.otf);
    font-weight: 500;
}
@font-face {
    font-family: Akrobat;
    src: url(../assets/fonts/Akrobat-Bold.otf);
    font-weight: 600;
}
@font-face {
    font-family: Akrobat;
    src: url(../assets/fonts/Akrobat-ExtraBold.otf);
    font-weight: 700;
}
@font-face {
    font-family: Akrobat;
    src: url(../assets/fonts/Akrobat-Black.otf);
    font-weight: 800;
}
@font-face {
    font-family: Slate Pro;
    src: url(../assets/fonts/SlatePro-Light.ttf);
    font-weight: 300;
}
@font-face {
    font-family: Slate Pro;
    src: url(../assets/fonts/SlatePro-LightItalic.ttf);
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: Slate Pro;
    src: url(../assets/fonts/SlatePro.ttf);
    font-weight: 400;
}
@font-face {
    font-family: Slate Pro;
    src: url(../assets/fonts/SlatePro-Italic.ttf);
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: Slate Pro;
    src: url(../assets/fonts/SlatePro-Bold.ttf);
    font-weight: 700;
}
@font-face {
    font-family: Slate Pro;
    src: url(../assets/fonts/SlatePro-BoldItalic.ttf);
    font-weight: 700;
    font-style: italic;
}

html {
    background-color: $deep-purple;
    height: 100%;
    scroll-behavior: smooth;
    font-family: "Akrobat", sans-serif;
}
body {
    height: 100%;
    margin: 0;
    background-color: unset;
    font-family: Slate Pro;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 1.2;
    @include media-breakpoint-up(sm) {
        font-size: 1.4rem;
    }
}
#root {
    height: 100%;
}
.page-container {
    height: 100%;
    position: relative;
    overflow-y: scroll;
}

.h1,
.h2,
.h3,
.h4 {
    font-family: "Akrobat", sans-serif;
}

.h1 {
    font-size: 3.2rem;
    font-weight: 900;
    @include media-breakpoint-up(sm) {
        font-size: 5.5rem;
    }
}

.h2 {
    font-size: 2.2rem;
    font-weight: 900;
    @include media-breakpoint-up(sm) {
        font-size: 3rem;
    }
}

.presmobtn {
    border-radius: 26px;
    padding: 10px 25px 5px;
    font-weight: 600;
    display: inline-block;
    border: none;
    box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.05);
    text-decoration: none;
}

.presmobtn:hover {
    text-decoration: none;
    color: auto;
}

.py-6 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
