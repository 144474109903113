$orange: #ff7b61;
$yellow: #ffea94;
$teal: #92cdd5;
$presmo-blue: #00c1e4;
$purple: #583467;
$deep-purple: #2f0c82;
$deep-purple2: #290d76;
$turquoise: #5dd6bb;
$light-grey: #f0f0f0;
$grey: #979797;
$tablet-v: 768px;
$tablet-h: 992px;
$mobile: 375px;
