@import "~bootstrap/scss/bootstrap";
.homepage {
    video,
    img {
        z-index: -1000;
        object-fit: cover;
    }
    .title {
        z-index: -900;
        color: white;
        > * {
            position: relative;
        }
        .part-1 {
            font-weight: 200;

            @include media-breakpoint-up(xs) {
                top: 0.5em;
                font-size: 6.2vw;
            }
            @include media-breakpoint-up(sm) {
                top: 0.6em;
                font-size: 3.7vw;
            }
            @include media-breakpoint-up(md) {
                top: 0.7em;
                font-size: 5vw;
            }
            @include media-breakpoint-up(lg) {
                top: 0.6em;
                font-size: 5vw;
            }
            @include media-breakpoint-up(xl) {
                top: 0.6em;
                font-size: 3.7vw;
            }
        }
        .part-2 {
            font-family: Akrobat;
            font-weight: 800;

            @include media-breakpoint-up(xs) {
                top: 0.5em;
                font-size: 11vw;
            }
            @include media-breakpoint-up(sm) {
                top: 0;
                font-size: 5vw;
            }
            @include media-breakpoint-up(md) {
                top: 0.05em;
                font-size: 6.7vw;
            }
            @include media-breakpoint-up(lg) {
                top: 0;
                font-size: 6.6vw;
            }
            @include media-breakpoint-up(xl) {
                top: 0.05em;
                font-size: 4.7vw;
            }
        }
    }
}
