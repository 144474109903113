@import "../../sass/variables.scss";
@import "~bootstrap/scss/bootstrap";
.teal-banner {
    background: $teal;
    &__white {
        font-size: 3.5rem;
        line-height: 1;
        font-weight: 550;
    }
    &__dark {
        font-size: 1.5rem;
        line-height: 30px;
        margin: 23px 0 25px 1px;
    }
    &__button {
        background: $yellow;
        color: black;
        border: none;
        margin-top: 3px;
    }
}
