.back-to-top {
    height: 50px;
    width: 50px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: none;

    &--show {
        display: inline;
    }
}
