@import "../../sass/variables.scss";
.contact-form {
    background: $orange;
    padding-bottom: 7rem;
    .hi-there {
        margin-left: -15px;
        @media (min-width: $tablet-v) {
            width: 80%;
        }

        @media (min-width: $tablet-h) {
            width: 70%;
        }
    }
    .icon {
        width: 40px;
        margin-right: 1rem;
    }

    &__button {
        background: $purple;
        color: white;
        transition: all 0.2s;
    }
    &__button:hover {
        background: $yellow;
        color: black;
    }
}
