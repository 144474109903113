@import "../../sass/variables.scss";

.footer {
    font-size: 1rem;
    color: $grey;
    background: $light-grey;
    &__icon {
        width: 40px;
    }
    p {
        margin: 0;
        width: 100%;
    }
}
